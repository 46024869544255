<template>
  <b-overlay :show="loading">
    <section>
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col md="12">
            <b-card>
              <b-form>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <label for="name">Judul</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider #default="{ errors }" rules="required" name="Judul">
                        <b-form-input id="Judul" v-model="form.name" placeholder="Masukkan judul soal"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <label for="Pilih Paket">Pilih Kategori Paket</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider #default="{ errors }" rules="required" name="Kategori Paket">
                        <v-select v-model="form.paket_id" label="text" :options="optpaket_id"
                          :reduce="(option) => option.value" @input="getDataMapel()" placeholder="- Pilih -"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" v-if="this.form.paket_id != null">
                    <b-form-group>
                      <label for="Pilih Paket">Pilih Ujian</label>
                      <small class="text-link"> <b> (opsional)</b></small>
                      <validation-provider #default="{ errors }" rules="" name="Ujian">
                        <v-select v-model="form.ujian_id" label="text" :options="optMapel"
                          :reduce="(option) => option.value" placeholder="- Pilih Ujian dahulu -"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <label for="Waktu (Detik)">Waktu (Detik)</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider #default="{ errors }" rules="required" name="Waktu">
                        <b-form-input id="timer_second" v-model="form.timer_second" placeholder="Masukkan Waktu (Detik)"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" v-if="type == 'pauli'">
                    <b-form-group>
                      <label for="Jumlah Opsi Soal">Jumlah Soal Kolom</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider #default="{ errors }" rules="required" name="Jumlah Opsi">
                        <b-form-input v-model="selectedOption" placeholder="Masukkan jumlah opsi soal"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      
                    </b-form-group>
                  </b-col>  
                  <b-col md="3" v-if="type == 'pauli'">
                    <b-form-group>
                      <label for="Jumlah Opsi Soal">
                        <strong class="text-primary">
                          <i>Total Bobot Kolom</i>
                        </strong>
                      </label>
                      <div>
                        <b-form-input disabled placeholder="Masukkan jumlah opsi soal"
                          :value="totalBobotSoal" />
                      </div>
                      
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
              <b-tabs v-if="type != 'pauli'">
                <!-- Pertanyaan -->
                <b-tab active>
                  <!-- title -->
                  <template #title>
                    <!-- <feather-icon icon="CheckCircleIcon" size="18" class="mr-50" /> -->
                    <span class="font-weight-bold">Pertanyaan</span>
                  </template>
                  <b-form>
                    <b-row>
                      <b-col>
                        <!-- <b-form-group label="Konten" label-for="Konten"> -->
                        <quill-editor id="Konten" v-model="form.content" :options="editor_options">
                        </quill-editor>
                        <!-- </b-form-group> -->
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
                <b-tab>
                  <template #title>
                    <!-- <feather-icon icon="HelpCircleIcon" size="18" class="mr-50" /> -->
                    <span class="font-weight-bold">Pembahasan</span>
                  </template>
                  <b-form>
                    <b-row>
                      <b-col>
                        <!-- <b-form-group label="Konten" label-for="Konten"> -->
                        <quill-editor id="Pembahasan" v-model="form.pembahasan" :options="editor_options" />
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
              <div v-if="type == 'pg' ||
                type == 'multiple_choice' ||
                type == 'benar_salah'
                ">
                <b-tabs class="mt-2">
                  <div v-for="(data, index) in form.soals" :key="index">
                    <b-tab>
                      <template #title>
                        <!-- <feather-icon icon="BookOpenIcon" size="18" class="mr-50" /> -->
                        <div v-if="type != 'pauli'">
                          <label class="font-weight-bold" v-if="index + 1 == 1">Opsi. A</label>
                          <label class="font-weight-bold" v-if="index + 1 == 2">Opsi. B</label>
                          <label class="font-weight-bold" v-if="index + 1 == 3">Opsi. C</label>
                          <label class="font-weight-bold" v-if="index + 1 == 4">Opsi. D</label>
                          <label class="font-weight-bold" v-if="index + 1 == 5">Opsi. E</label>
                        </div>
                      </template>
                      <b-form>
                        <b-row>
                          <b-col>
                            <!-- <label class="font-weight-bold">Jawaban</label> -->

                            <quill-editor id="Konten" v-model="data.content" :options="editor_options" />
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- </b-form-group> -->
                          <!-- <b-form-group label="Konten" label-for="Konten"> -->
                          <b-col md="2" v-if="type != 'pg' || type != 'pauli'">
                            <label class="font-weight-bold mt-2" v-if="index + 1 == 1">Jawaban Opsi. A</label>
                            <label class="font-weight-bold mt-2" v-if="index + 1 == 2">Jawaban Opsi. B</label>
                            <label class="font-weight-bold mt-2" v-if="index + 1 == 3">Jawaban Opsi. C</label>
                            <label class="font-weight-bold mt-2" v-if="index + 1 == 4">Jawaban Opsi. D</label>
                            <label class="font-weight-bold mt-2" v-if="index + 1 == 5">Jawaban Opsi. E</label>
                            <b-form-checkbox v-if="type == 'multiple_choice'" v-model="data.is_correct" value="1"
                              class="custom-control-primary">
                              Kunci Jawaban
                            </b-form-checkbox>
                            <b-form-radio-group v-if="type == 'benar_salah'" v-model="data.is_true_or_false"
                              :options="bsSoal" name="radios-stacked" stacked />
                          </b-col>
                          <b-col md="10" v-if="type != 'pg'">
                            <label class="font-weight-bold mt-2">Upload file format: jpg, png, pdf, doc, mp3, mp4
                              (opsional)</label>
                            <b-form-file :id="data.id" class="mb-2" v-model="data.opsi_file"
                              placeholder="Pilih file untuk di upload atau unggah..." drop-placeholder="Drop file here..."
                              no-drop accept=".jpg, .png, .pdf, .doc, .mp3, .mp4" />
                          </b-col>
                          <b-col md="12" v-if="type == 'pg'">
                            <label class="font-weight-bold mt-2">Upload file format: jpg, png, pdf, doc, mp3, mp4
                              (opsional)</label>
                            <b-form-file :id="data.id" class="mb-2" v-model="data.opsi_file"
                              placeholder="Pilih file untuk di upload atau unggah..." drop-placeholder="Drop file here..."
                              no-drop accept=".jpg, .png, .pdf, .doc, .mp3, .mp4" />
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-tab>
                  </div>
                </b-tabs>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-alert variant="warning" show class="p-1">
              <p class="text-dark">
                <i>Sebelum membuat 1 kolom pauli, pastikan anda mengikuti arahan pembuatannya <strong class="cursor-pointer" @click="$bvModal.show('petunjuk-modal')">disini</strong></i>
              </p>
            </b-alert>
            <b-card :title="type == 'pauli' ? `Soal Kolom ${ form.soals.length > 0 ? `(${form.soals.length})` : '' }` : 'Opsi Soal'">
              <div v-if="type == 'pauli'">
                <b-alert class="p-1" show variant="info" v-if="form.soals.length < 1">
                  Harap isi jumlah soal dalam 1 kolom
                </b-alert>
                <b-alert v-if="form.soals.length >= 1" class="mb-1 p-1" show variant="info">
                  <p class="text-dark">
                    Anda dapat mengatur seluruh bobot soal dengan <strong>meng-input total bobot 1 kolom</strong> disini.
                    Lalu  dari angka tersebut akan dibagi rata sesuai jumlah soal dibawah. <i>(opsional)</i>
                  </p>
                  <b-row>
                    <b-col sm="12" md="6">
                      <b-form-input 
                        type="number"
                        placeholder="Ex: 10"
                        v-model="bobotKolom"
                      />
                    </b-col>
                  </b-row>
                </b-alert>
                <b-row v-for="(data, index) in form.soals" :key="index" class="mb-1">
                  <b-col sm="12" md="6" lg="4">
                    <b-form-group>
                      <label :for="'h-baris-' + (index + 1)"><strong><i>{{
                        "Soal Baris " + (index + 1)
                      }}</i></strong></label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider #default="{ errors }" rules="required" name="Soal">
                        <b-form-input :id="'h-baris-' + data.id" placeholder="Ex: 1 + 2" v-model="data.content"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6" lg="4">
                    <b-form-group>
                      <label :for="'h-baris-' + (index + 1)">{{
                        "Jawaban Baris " + (index + 1)
                      }}</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider #default="{ errors }" rules="required" name="Jawaban">
                        <b-form-input :id="'h-baris-' + data.id" placeholder="Isi 1 atau 0" v-model="data.secret_key"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6" lg="4">
                    <b-form-group>
                      <label :for="'bobot-baris-' + (index + 1)">{{
                        "Bobot Soal " + (index + 1)
                      }}</label>
                      <small class="text-danger"> <b> (*)</b></small>
                      <validation-provider #default="{ errors }" rules="required" name="Bobot">
                        <b-form-input :id="'bobot-baris-' + data.id" placeholder="Isi angka" v-model="data.bobot"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-button v-if="form.soals != ''" variant="success" @click.prevent="submit" class="w-full ">
                  Simpan
                </b-button>
                <b-button v-if="form.soals != ''" variant="outline-success" @click.prevent="submit(true)"
                  class="w-full mx-1 ">
                  Simpan dan Buat Lagi
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col sm="12" md="4" lg="4">
            <!-- <b-card no-body> -->
            
            <!-- </b-card> -->
          </b-col>
        </b-row>
      </validation-observer>
    </section>
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      id="petunjuk-modal"
    >
    <b-alert variant="warning" show>
              <h4 class="alert-heading">
                Petunjuk
              </h4>
              <div class="alert-body">
                <!-- <ul> -->
                <ol type="1">
                  <li>
                    </strong>Untuk mengisi soal pauli, admin tentukan berapa opsi soal yang akan diisi di form
                    <strong>"Jumlah Opsi
                      Soal"</strong>.
                  </li>
                  <li>
                    Lalu muncul jumlah opsi soal dan jawaban yang harus diisi oleh admin..
                  </li>
                  <li>
                    Isi form baris 1 dengan soal. <br> Contoh: 1 + 3 atau 3 + 4
                  </li>
                  <li>
                    Lalu sesuaikan isi form jawaban baris 1 dengan jawabannya modulusnya. <br> Contoh: <br>
                    angka <strong><u>1</u></strong> jika hasilnya <strong><u>Genap</u></strong> <br>
                    atau <br>
                    angka <strong><u>0</u></strong> jika hasilnya <strong><u>Ganjil</u></strong>
                  </li>
                  <li>
                    Ulangi langkah 3 dan 4 sesuai dengan baris dan jawaban baris selanjutnya.
                  </li>
                </ol>
                <!-- </ul> -->
              </div>
            </b-alert>

      <template #modal-footer>
        <b-button @click.prevent="$bvModal.hide('petunjuk-modal')">OK</b-button>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
// import 'vue-select/dist/vue-select.css';
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import id from "vee-validate/dist/locale/id.json";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BOverlay,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
const limitSoal = 5; // limit pembuatan soal
const mb = 1024;

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BOverlay,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      bobotKolom: null,
      loading: false,
      mapels: [],
      // default data
      form: {
        paket_id: null,
        name: null,
        pembahasan: null,
        timer_second: 30,
        soals: [],
      },
      opsi: null,
      active_soal_attachment: false,
      judul: null,
      selectedOption: 0,
      file: null,
      sum_option: null,
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      optMapel: [],
      optmapel_id: [],
      optpaket_id: [],
      timer_second: 0,
      name: null,
      type: "pauli",
      mapels: [
        {
          value: "Matematika Kelas X IPA 1",
        },
      ],
      is_correct: null,
      typesSoal: [
        {
          label: "Pilihan Ganda",
          value: "pg",
        },
        {
          label: "Multiple Choice",
          value: "multiple_choice",
        },
        {
          label: "Pauli",
          value: "pauli",
        },
        {
          label: "Pernyataan Benar Salah",
          value: "benar_salah",
        },
      ],
      bsSoal: [
        { text: "Benar", value: "benar" },
        { text: "Salah", value: "salah" },
      ],
      opsisSoal: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
      ],
      sum_options: [
        {
          id: 1,
          name: 1,
        },
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
        {
          id: 4,
          name: 4,
        },
        {
          id: 5,
          name: 5,
        },
      ],
      masterOptions: [
        {
          id: 1,
          label: "A",
          value: "A",
        },
        {
          id: 2,
          label: "B",
          value: "B",
        },
        {
          id: 3,
          label: "C",
          value: "C",
        },
        {
          id: 4,
          label: "D",
          value: "D",
        },
        {
          id: 5,
          label: "E",
          value: "E",
        },
      ],
      availableOptions: [
        {
          content: "",
        },
      ],
      soals: [],
      activeAction: null,
    };
  },
  watch: {
    bobotKolom(val) {
      if(val && parseInt(val) > 0) {
        const totalBobot = parseInt(val)
        let soals = Object.assign([], this.form.soals)
        const bobotEachSoal = parseFloat(totalBobot / soals.length).toFixed(2)
        soals = soals.map(soal => {
          soal.bobot = bobotEachSoal
          return soal
        })

        this.form.soals = soals
      }
    },
    selectedOption(val) {
      // let num = parseInt(val.name);
      this.form.soals = [];
      for (let i = 0; i < val; i++) {
        // let option = this.getOption(i);
        this.form.soals.push({
          key: i,
          content: null,
          secret_key: null,
          bobot: 1,
        });
      }
      // for (let i = 0; i < val; i++) {
      //     let option = this.getKunjaw(i)
      //     this.masterOptions.push({
      //       id:i,
      //       label:i,
      //       value:i,
      //       option,
      //     })
      //   }
    },
  },
  computed: {
    totalBobotSoal() {
      return this.form.soals.reduce((total, soal) => {
        total += parseFloat(soal.bobot)
        return total
      }, 0).toFixed(2)
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    resetForm() {
      this.form = {
        paket_id: null,
        mapel_id: null,
        name: null,
        pembahasan: null,
        timer_second: 30,
        soals: [],
      };
      this.selectedOption = 0
    },
    clearForm() {
      // this.form.soals[] = null
      this.type = "pauli";
    },
    addAttachSoal(ind, e) {
      document.getElementById(`file_soal_${ind}`).click();
    },
    addAttachSoalOption(ind, e) {
      document.getElementById(ind).click();
    },
    addSoal() {
      if (this.dataSoal.length >= limitSoal) {
        this.$vs.notify({
          title: "Batas Pembuatan Soal Tercapai",
          text: "",
          variant: "warning",
          position: "top-right",
        });
        return false;
      }

      let data = {
        no: this.dataSoal.length + 1,
        judul: null,
        name: null,
        file: null,
        option: null,
        soals: [],
        // is_correct: null,
        timer_second: 1,
      };

      if (this.selectedOption != null) {
        for (let i = 0; i < this.selectedOption; i++) {
          let option = this.getOption(i);
          data.soals.push({
            key: i,
            option,
            file: null,
            content: null,
          });
        }
      }

      //
      this.dataSoal.push(data);
    },
    updateDataSoal(val) {
      let vm = this;
      this.$route.meta.breadcrumb[2].url =
        "/soal/daftarsoal?mp=" + this.$route.query.mp;
      val.forEach((item, index) => {
        // Change when user choose Jumlah Opsi
        if (form.selectedOption != null) {
          let numOpsi = parseInt(form.selectedOption.name);
          if (form.soals.length > 0) {
            form.soals = [];
          }

          if (form.soals.length < 1) {
            for (let i = 0; i < numOpsi; i++) {
              let option = this.getOption(i);
              vm.dataSoal[index].soals[i] = {
                key: i,
                option,
                file: null,
                content: null,
              };
            }
          }
        }
      });
    },
    getEditorOptions(id_file_input) {
      // get event link click in dynamic options
      return {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
            handlers: {
              link: function (val) {
                document.getElementById(id_file_input).click();
              },
            },
          },
        },
      };
    },
    getOption(num) {
      switch (num) {
        case 0:
          num = "A";
          break;
        case 1:
          num = "B";
          break;
        case 2:
          num = "C";
          break;
        case 3:
          num = "D";
          break;
        case 4:
          num = "E";
          break;
      }

      return num;
    },

    getKunjaw(num) {
      switch (num) {
        case 0:
          num = "A";
          break;
        case 1:
          num = "B";
          break;
        case 2:
          num = "C";
          break;
        case 3:
          num = "D";
          break;
        case 4:
          num = "E";
          break;
      }

      return num;
    },
    removeOptions(ind) {
      if (this.soals.length > 1) {
        this.soals.splice(ind, 1);
      }
    },
    optionNull() {
      let hasNullOption = this.form.soals.find(
        (soal) =>
          soal.content == null || soal.content == "" || soal.secret_key == null || !soal.bobot
      );

      return hasNullOption ? true : false;
    },
    addOption() {
      // untuk menambah opsi
      this.soals.push({
        option: null,
        content: null,
      });
    },
    fileToBase64(fileObj) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(fileObj);
      });
    },
    validateForm() {
      // Check if there question with empty soal || empty secret
      let thereEmptyQuest = 0;

      if (this.type == "pauli") {
        this.form.name == "" ||
          this.form.name == null ||
          this.form.bobot == "" ||
          this.form.bobot == null ||
          this.form.paket_id == "" ||
          this.form.paket_id == null ||
          this.form.timer_second == "" ||
          this.form.timer_second == null;
      }

      if (this.type == null || thereEmptyQuest > 0) {
        return false;
      }

      return true;
    },

    async submit(createAgainAction = false) {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          // create var for form data
          const fd = new FormData();
          if (this.type == "pauli") {
            if (this.optionNull()) {
              this.displayWarning({
                title: "Perhatian",
                text: "Tidak dapat menambahkan soal, harap isi opsi/soal yang masih kosong",
              });
              return false;
            }
          }

          const soals = this.form.soals.map(soal => {
            soal.bobot = parseFloat(soal.bobot)
            return soal
          })
          let payload = {
            user_id: this.user.id,
            category_paket_id: this.form.paket_id,
            name: this.form.name,
            pembahasan: this.form.pembahasan,
            timer_second: this.form.timer_second,
            soals,
          };

          try {
            (this.loading = true),
              await this.$store.dispatch("banksoal/CRUPauli", [payload]);
            (this.loading = false),
              (this.activeAction = "tambah"),
              this.displaySuccess({
                text: "Soal berhasil di" + this.activeAction,
              });

            this.resetForm();
            if (createAgainAction != true) {
              let url = "/master-admin/bank-soal"
              if(payload.category_paket_id) {
                url += `?cat=${payload.category_paket_id}`
              }
              this.$router.push(url);
            }
          } catch (e) {
            (this.loading = false), this.displayError(e);
            return false;
          }
        }
        if (!success) {
          this.pesanGagal();
        }
      });
    },
    checkQuery() {
      if (this.$route.query.s == undefined) {
        this.$router.push({ path: "/soal" });
      }
    },
    handleOptionFile(e, id) {
      // add file dynamic option to data
      let objFile = e.target.files[0];
      this.dataSoal[id.soal].soals[id.opsi].file = objFile;

      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.dataSoal[id.soal].soals[id.opsi].file =
          URL.createObjectURL(objFile);
      } else {
        this.dataSoal[id.soal].soals[id.opsi].file = null;
      }
    },
    handleFile(ind, e) {
      // add soal file to data
      let objFile = e.target.files[0];
      this.dataSoal[ind].file = objFile;
      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.dataSoal[ind].preview_file = URL.createObjectURL(objFile);
      } else {
        this.dataSoal[ind].preview_file = null;
      }
    },
    unhandleFile(ind, e) {
      // remove soal file
      this.dataSoal[ind].file = null;
      this.dataSoal[ind].preview_file = null;
      e.target.value = null;
    },
    unhandleFileOption(e, id) {
      // remove file dynamic option
      this.dataSoal[id.soal].soals[id.opsi].file = null;
      this.dataSoal[id.soal].soals[id.opsi].preview_file = null;
      e.target.value = null;
    },
    resetHandleFile(ind, e) {
      this.$refs["file_" + ind].value = "";
    },
    resetHandleOptionFile(e, id_file_input) {
      this.$refs[id_file_input].value = "";
    },
    doFormatRupiah() {
      this.banksoalDataId.price = this.formatRupiah(this.banksoalDataId.price);
      this.banksoalDataId.max_quota = this.formatRupiah(
        this.banksoalDataId.max_quota
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalUpload() {
      this.id = this.$route.params.id;
      this.activeAction = "upload";
      this.doFormatRupiah();
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.doFormatRupiah();
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = form.id;
      this.banksoalDataId = item;
      this.activeAction = "ubah";
      this.doFormatRupiah();
      this.titleModal = "Ubah Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("banksoal/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "AlertCircleIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getDataPaket2() {
      const payload = {};
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let paket_id = response.data.data;
          paket_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaket_id = paket_id;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    switchLoc() {
      // switch the locale.
      this.locale = this.locale === "id" ? "id" : "id";
      localize(this.locale, id);

      // re-validate to re-generate the messages.
      // this.$refs.simpleRules.validate();
    },
    async getDataMapel() {
      const payload = {
        user_id: this.user.id,
        category_paket_id: this.form.paket_id != null ? this.form.paket_id : null,
      };
      this.$store
        .dispatch("adminujian/index", payload)
        .then((response) => {
          // this.loading = false;
          let optMapel = response.data.data;
          optMapel.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optMapel = optMapel;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {
    // this.getDataPaket();
  },
  mounted() {
    this.getDataPaket2();
    this.switchLoc();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
